import Vue from 'vue'
import VueRouter from 'vue-router'
import { loginService } from '../services/login.service';

Vue.use(VueRouter)
import store from '../store'

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		meta: {
			guestOnly: true,
			guest: true,
		},
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		meta: {
			guest: true,
		},
		component: () => import('../views/Layout.vue'),
	},
	{
		path: '/',
		name: 'صفحه اصلی',
		//layout: "dashboard",
		meta: {
			layoutClass: 'layout-landing',
			guest: true,
		},
		component: () => import('../views/Landing.vue'),
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/user/projects',
		name: 'پروژه ها',
		layout: "dashboard",
		component: () => import('../views/user/Projects.vue'),
	},
	{
		path: '/user/projects/modify/:id',
		name: 'ویرایش',
		layout: "dashboard",
		component: () => import('../views/admin/projects/Modify.vue'),
	},
	{
		path: '/user/projects/new',
		name: 'درخواست پروژه جدید',
		layout: "dashboard",
		component: () => import('../views/user/Project_new.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/activate-account/:id',
		name: 'فعال سازی حساب کاربری',
		meta: {
			guest: true,
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/user/auth/Activate.vue'),
	},
	{
		path: '/Profile/services',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: 'حساب کاربری',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			guestOnly: true,
			guest: true,
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
	{
		path: '/admin/dashboard',
		name: 'داشبرد',
		layout: "dashboard",
		meta: {
			layoutClass: '',
		},
		component: () => import('../views/admin/Dashboard.vue'),
	},
	{
		path: '/admin/users',
		name: 'مدیریت کاربران',
		layout: "dashboard",
		meta: {
			layoutClass: '',
		},
		component: () => import('../views/admin/Users.vue'),
	},
	{
		path: '/admin/projects',
		name: 'مدیریت پروژه ها',
		layout: "dashboard",
		meta: {
			guest: true,
			layoutClass: '',
		},
		component: () => import('../views/admin/projects/Index.vue'),
	},
	{
		path: '/admin/projects/list',
		name: 'لیست پروژه ها',
		layout: "dashboard",
		meta: {
			layoutClass: '',
		},
		component: () => import('../views/admin/projects/List.vue'),
	},
	{
		path: '/admin/projects/modify/:id',
		name: 'ویرایش پروژه',
		layout: "dashboard",
		meta: {
			layoutClass: '',
		},
		component: () => import('../views/admin/projects/Modify.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {

	const token = localStorage.getItem('token')
	const user = localStorage.getItem('user')

	if(!user && !token)
	{
		if((to.meta.guestOnly || to.meta.guest))
		{
			return next();
		}
 
		// none user trying to access user area ? do not allow
		return next({path:'/sign-in'}); // 
	}

    Vue.prototype.$me = null;

    loginService.getMe().then(response => {    
		if (response.status == 200)
		{
			return response.json();            
		}
		else
		{
		  next();
		}
	}).then((response) => {
		console.log(response.status)
		console.log(response)
		if (response.status == 200)
		{
			if(response.OK)
			{
				if(response.data.token && response.data.user)
				{
					store.commit('login/loginSuccess', response);
					console.log(to);
					if(to.meta.guestOnly)
					{
						// none user trying to access user area ? do not allow
						return next({path:'/dashboard'}); // 
					}
					else{
						return next()
					}
				}
			}
		}
		else if(response.status == 402)
		{
			if(response.data.token == 'undefined')
				return next();
			console.log(response.data)

			store.commit('login/loginFailure');
			// invalid token 
			return next()
		}
	}).catch((e) =>{
		next({path:'/'})
	})

	
});
export default router
