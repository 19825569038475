/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
// import './plugins/click-away'
import store from './store'
import './scss/app.scss';
import { loginService } from './services/login.service';
import axios from 'axios';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

Vue.use(Antd);

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

Vue.use(VuePersianDatetimePicker, {
  name: 'custom-date-picker',
  props: {
    format: 'YYYY-MM-DD HH:mm',
    displayFormat: 'jYYYY-jMM-jDD HH:mm',
    editable: false,
    inputClass: 'form-control my-custom-class-name',
    placeholder: 'Please select a date',
    altFormat: 'YYYY-MM-DD HH:mm',
    color: '#00acc1',
    autoSubmit: true,
    //...  
    //... And whatever you want to set as default.
    //... 
  }
});

new Vue({
  store,
  router,
  me: null,
  render: h => h(App),
  mounted () {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    
    const token = localStorage['token'];

    if(token)
    {
      axios.defaults.headers.common['Authorization'] = token;;
    }
    axios.interceptors.request.use(function (config) {
      
      // a request has been intercepted ..
      // do smth ?

      // return config.
      return config;
    });
  }
}).$mount('#app')