import Vue from 'vue';
import Vuex from 'vuex';
//general
import { login } from './login.module.ts';
Vue.use(Vuex);

const store = new Vuex.Store({
    
    modules: {
        login
    }

});
export default store; 