import { loginService } from '../services/login.service'
import Vue from 'vue'
import router from '../router'
const state = {
    isLogin: false,
    token:null,
    user:null,
}
const actions = {
    login({commit}, { email, password }) {
        commit('loginRequest', { email })
    
        loginService.login(email, password)	.then(response => {    
            if (response.status == 200) {
              return response.json()            
            } else {
                alert('asd')
            }
          })
          .then((response) => {
            
            if(response.OK)
            {
                console.log(response)
                if(response.data.token && response.data.user)
                {
                    commit('loginSuccess', response)
                    router.push('/profile')
                }
            }
          })
          .catch((e) => {
              console.log(e)
          })
        /**
         * 
        .then(user => {
            console.log("login success")
            console.log(result)
            router.push('/home')
            console.log(user)
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user))
            }

            return user
        })
         */
 
    },
    logout({commit}) {
        commit('resetState')
        router.push('/')
    },
    clearState ({ commit }) {
        commit('resetState')
    },
}
const mutations = {
    resetState (state) {
        state.isLogin=false
        state.token=null
        state.user=null
    },
    loginRequest(state, user) {
        state.isLogin=true
        state.token=null
        state.user=user
    },
    loginSuccess(state, response) {
        if(response.OK)
        {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            // update current user
            Vue.prototype.$me = response.data.user

            state.isLogin=true
            state.token=response.data.token
            state.user=response.data.user
        }
        else
        {

        }
    },
    loginFailure(state) {
        state.isLogin=false
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        Vue.prototype.$me = null

    },
}
export const login = {
    namespaced: true,
    state,
    actions,
    mutations
}